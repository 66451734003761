var Tegeldepot = Tegeldepot || {};

Tegeldepot.ElementShift = class {
    constructor(options) {
        this.setMobile = this.setMobile.bind(this);
        this.setDesktop = this.setDesktop.bind(this);

        this.options = jQuery.extend({}, {
            elementSelector: '.element-selector',
            elementHolderDesktop: '.holder-desktop',
            elementHolderMobile: '.holder-mobile',
            isMobile: false,
            isDesktop: false,
        }, options);

        this.hookEvents();
    }

    hookEvents() {
        this.checkSize();

        let timeout;
        window.addEventListener('resize', () => {
            if (timeout) {
                window.cancelAnimationFrame(timeout);
            }
            timeout = window.requestAnimationFrame(() => {
                this.checkSize();
            });
        }, false);
    }

    checkSize() {
        if (window.matchMedia('screen and (max-width:' + bp.medium + 'px)').matches && !this.options.isMobile) {
            this.setMobile();
        } else if (!this.options.isDesktop) {
            this.setDesktop();
        }
    }

    setMobile() {
        const elementSelector = jQuery(this.options.elementSelector);

        if (elementSelector.parent(this.options.elementHolderDesktop)) {
            const detachedElement = elementSelector.detach();
            detachedElement.appendTo(this.options.elementHolderMobile);
            this.options.isMobile = true;
        }
    }

    setDesktop() {
        const elementSelector = jQuery(this.options.elementSelector);

        if (elementSelector.parent(this.options.elementHolderMobile)) {
            const detachedElement = elementSelector.detach();
            detachedElement.appendTo(this.options.elementHolderDesktop);
            this.options.isDesktop = true;
        }
    }

};